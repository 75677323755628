const includeEmailInSteps = () => {
  return process.env.REACT_APP_VENDOR === "myq";
};

const getImage = index => {
  switch (process.env.REACT_APP_VENDOR) {
    case "myq":
      return [
        "superheroes_zakladni_zena_koala_01.png",
        "superheroes_zakladni_zena_koala_02.png",
        "superheroes_zakladni_zena_koala_03.png"
      ][index];
    case "knm":
      return ["kyocera_logo_black.png", "kyocera_logo_black.png", "kyocera_logo_black.png"][index];
    case "ta":
    case "utax":
      return ["ta_guide_woman_01.png", "ta_guide_woman_02.png", "ta_guide_woman_03.png"][index];
    default:
      return [
        "superheroes_zakladni_zena_koala_01.png",
        "superheroes_zakladni_zena_koala_02.png",
        "superheroes_zakladni_zena_koala_03.png"
      ][index];
  }
};

const createStepsWithStyles = TopMenuStyles => {
  const emailStep = {
    target: "#joyride-root-target",
    title: "Do you want to know more? ",
    content: "Write down your Email for further information ",
    disableBeacon: true,
    tooltipEmail: true,
    showSkipButton: true,
    placement: "center",
    img: getImage(0),
    arrow: true
  };

  let steps = [];
  let technology;
  let technologyLong;
  switch (process.env.REACT_APP_VENDOR) {
    case "knm":
      technology = "KNM";
      technologyLong = "KYOCERA Net Manager";
      break;
    case "myq":
    case "wietholt":
      technology = "MyQ";
      break;
    case "ta":
    case "utax":
      technology = "aQrate";
      break;
    default:
      technology = "MyQ";
  }

  let contentStep1 =
    process.env.REACT_APP_VENDOR === "knm"
      ? "You can customize KNM based on your usage. To see the different customization options, simply choose your login PIN based on your most frequently used commands: (11 – Juan, the Power User; 22 – Sophie, the Quick User; 33 – Stephen, the Light User; 44 – Jayne, the Credit User; 55 – Laura, the Quota User)"
      : "To log in, simply enter 11 in the “Password” section. There are 5 different scenarios, use PIN 11, 22, 33, 44 or 55. If you want to change the set-up of icons, send me what it should look like and the names of the user.";
  steps = [
    {
      target: "#joyride-root-target",
      title: `Welcome to the ${technology} Showcase`,
      content: `${
        technologyLong ? technologyLong : technology
      } brings your multifunctional devices under control, lowers your expenses, and helps the environment by reducing unnecessary waste.`,
      info:
        "In this demo, you will go through the same interface as can be found on a real printer. Click “Next” to get started.",
      disableBeacon: true,
      clickTarget: null,
      placement: "center",
      showSkipButton: true,
      img: getImage(0)
    },
    {
      target: "#k42-step-1",
      inputTarget: "#k42-step-1-input",
      title: "Login with PIN",
      content: contentStep1,
      info:
        "You can also use a card reader, QR code, or your username and password to authenticate yourself.",
      autofill: 11,
      disableBeacon: true,
      clickTarget: null,
      placement: "auto",
      showSkipButton: true,
      img: getImage(1)
    },
    {
      target: "#k42-step-2",
      title: "Click on OK",
      content: "Now just click “OK” to log in.",
      disableBeacon: true,
      clickTarget: "#k42-step-2",
      placement: "right",
      showSkipButton: true,
      img: getImage(1)
    },
    {
      target: "body",
      title: "This is the dashboard",
      content: "This is the place where you can control the device.",
      info: "Dashboard applications can be customized per user.",
      disableBeacon: true,
      clickTarget: null,
      placement: "center",
      showSkipButton: true,
      img: getImage(0)
    },
    {
      target: ".A3",
      title: "Let's try to display your print queue",
      content: "Now click the “My Jobs” tile to display all your pending jobs.",
      disableBeacon: true,
      clickTarget: ".A3",
      placement: "right",
      showSkipButton: true,
      img: getImage(1)
    },
    {
      target: "body",
      title: "Controlling your jobs",
      content:
        "From this screen, you can manage all the pending jobs, favorite jobs, and your jobs history.",
      info: "Dashboard applications can be customized per user.",
      disableBeacon: true,
      clickTarget: null,
      placement: "center",
      showSkipButton: true,
      img: getImage(0)
    },
    {
      target: "ons-carousel:first-child",
      title: "Let's print some pages",
      content: "Select the jobs you want to print.",
      info: "You can also swipe the job to the right to print it instantly",
      disableBeacon: true,
      clickTarget: "job-list-row",
      placement: "auto",
      showSkipButton: true,
      img: getImage(1)
    },
    {
      target: `.${TopMenuStyles["button_selected"]}`,
      title: "Now click the print icon",
      content: "To print the selected job, just click the print icon.",
      info: "You can also swipe the job to the right to print it instantly",
      disableBeacon: true,
      placement: "auto",
      showSkipButton: true,
      img: getImage(1)
    },
    {
      target: "#joyride-root-target",
      title: "Well done!",
      content:
        "You have just printed your first job. Let's go back to the dashboard to discover some other features.",
      info:
        "On this screen you can also display your print history or your favorite recurring jobs.",
      disableBeacon: true,
      placement: "center",
      showSkipButton: true,
      img: getImage(2)
    },
    {
      target: `.${TopMenuStyles.container}`,
      title: "Navigate back to the dashboard",
      content: "Click the “back” icon to navigate back.",
      disableBeacon: true,
      placement: "auto",
      showSkipButton: true,
      clickTarget: `.${TopMenuStyles.container}`,
      img: getImage(1)
    },
    {
      target: "#joyride-root-target",
      title: "There's more to discover",
      content: `With ${technology} you can save time, save money, and save the planet. Securely. Feel free to look around and discover our intuitive user interface.`,
      disableBeacon: true,
      info: "Try to scan a page and send it to your email. Can you do it?",
      placement: "center",
      img: getImage(0)
    },
    {
      target: "#joyride-root-target",
      title: "Would you like to continue?",
      content: `Feel free to continue exploring the ${technology} interface, log out, or log in as a different user.`,
      disableBeacon: true,
      info: "The other login PINs are: 22, 33, 44, 55",
      placement: "center",
      img: getImage(0)
      //arrow: true
    }
  ];
  if (includeEmailInSteps()) {
    steps = [...steps, emailStep];
  }

  return steps;
};

const createSteps = async () => {
  const styles = await import(/* webpackMode: "eager" */
  `../../components/TopMenu/${process.env.REACT_APP_VENDOR}/TopMenu.module.scss`);

  return createStepsWithStyles(styles);
};

const oneOffSteps = () => {
  let steps = new Map();

  steps.set("classification", {
    target: `#classification-container`,
    title: `On your SharePoint...`,
    content:
      "After scanning, you'll see this document in a specific folder e.g. on your SharePoint",
    info: "Customisable",
    disableBeacon: true,
    placement: "right",
    offset: window && window.innerWidth > 900 ? 300 : 50,
    showSkipButton: false,
    img: getImage(0)
  });

  return steps;
};

export const createCustomStep = stepName => {
  let steps = oneOffSteps();

  return steps.get(stepName);
};

export default createSteps;
