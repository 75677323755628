import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Joyride from "react-joyride";
import Tooltip from "../../components/Tooltip/Tooltip";
import { initOneStepGuide } from "../../actions/guide";
import { SKIP_GUIDE } from "../../constants/ActionTypes";

const OneStepGuide = ({ stepName, onClose }) => {
  const guide = useSelector(state => state.guide);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(initOneStepGuide(stepName));
  }, []);

  const skip = () => {
    // For some reason, this has to be implemented this way. Importing from /actions/guide doesn't work.
    dispatch({ type: SKIP_GUIDE });

    onClose && onClose();
  };

  let attachActions = step => {
    return { ...step, skip };
  };

  const styles = {
    spotlight: {
      outline: "3px solid red",
      borderRadius: 0
    },
    options: {
      overlayColor: "rgba(0, 0, 0, 0.6)"
    }
  };

  return (
    <>
      {guide.steps && (
        <Joyride
          disableOverlayClose={true}
          spotlightClicks={true}
          spotlightPadding={0}
          styles={styles}
          steps={guide.steps.map(attachActions)}
          stepIndex={guide.current}
          continuous={false}
          tooltipComponent={Tooltip}
          floaterProps={{ offset: 30, hideArrow: true }}
        />
      )}
    </>
  );
};

export default OneStepGuide;
